<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.usersByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="usersPageHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="users"
            :search="searchString"
            class="elevation-1"
            multi-sort>
            <template v-slot:item.role="{ item }">
              {{ getTranslatedRole(item.role) }}
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t('tables.users') }} </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>
                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(save)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" hidden md="6" sm="12">
                                <v-text-field v-model="editedItem.id" :label="$t('usersPage.id')"></v-text-field>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="first_name">
                                  <v-text-field
                                    v-model="editedItem.first_name"
                                    name="first_name"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.firstName')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="last_name">
                                  <v-text-field
                                    v-model="editedItem.last_name"
                                    name="last_name"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.lastName')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|email|max:200"
                                  v-slot="{ errors }"
                                  name="email"
                                  ref="emailRef">
                                  <v-text-field
                                    v-model="editedItem.email"
                                    name="email"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.email')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  :rules="editedItem.id === null ? 'required|min:6|max:50' : 'min:6'"
                                  v-slot="{ errors }"
                                  name="password">
                                  <v-text-field
                                    v-model="editedItem.password"
                                    :required="editedItem.id === null"
                                    name="password"
                                    clearable
                                    counter="50"
                                    :label="$t('usersPage.password')"
                                    type="password"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required" v-slot="{ errors }" name="role">
                                  <v-autocomplete
                                    v-model="editedItem.role"
                                    :items="roles"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('usersPage.role')"
                                    name="role"
                                    :error-messages="errors"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider v-slot="{ errors }" name="organisation">
                                  <v-autocomplete
                                      v-model="editedItem.organisation_id"
                                      :items="organisations"
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('usersPage.organisation')"
                                      name="organisation"
                                      :error-messages="errors"
                                      :no-data-text="$t('select.noDataAvailable')"
                                      @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col v-if="showClients" cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required" v-slot="{ errors }" name="client">
                                  <v-autocomplete
                                    v-model="editedItem.client"
                                    :items="clients"
                                    item-text="company_name"
                                    item-value="id"
                                    :label="$t('usersPage.client')"
                                    name="client"
                                    :error-messages="errors"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showDeleteUserModal(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-dialog v-model="deleteUserModal" max-width="650">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('usersPage.deleteUser') }}
            <v-spacer></v-spacer>
            <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteUserModal"></v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                {{ $t('usersPage.deleteMessage') }} <b>{{ userName }}</b
                >?
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!enableDelete" class="primary" text @click="deleteUser">
              {{ $t('buttons.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { usersPageHeaders } from '@/mixins/data-table-headers';
import { defaultUser } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDate } from '@/utils/formatDate';

export default {
  name: 'Users',
  components: { ButtonSubmit },
  data: () => ({
    searchString: '',
    dialog: false,
    headers: '',
    users: [],
    roles: [],
    organisations: [],
    clients: [],
    formTitle: 'usersPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    deletedItem: {},
    editedIndex: -1,
    enableSave: true,
    enableDelete: true,
    deleteUserModal: false,
    userName: '',
    loading: false,
  }),
  created() {
    this.editedItem = Object.assign({}, defaultUser);
    this.loadRoles();
    this.loadClients();
    this.loadOrganisations();

    this.headers = 'user';
    this.loadAllUsers();
  },
  computed: {
    usersPageHeaders() {
      return usersPageHeaders(i18n);
    },
    showClients() {
      return this.editedItem.role && this.editedItem.role == 'client';
    },
  },
  methods: {
    async loadAllUsers() {
      await this.$store.dispatch('users/getAllUsers').then((res) => {
        this.users = res.data.map((item) => {
          return { ...item, created_at: formatDate(item.created_at) };
        });
      });
    },
    async loadRoles() {
      await this.$store.dispatch('roles/getAllRoles').then((res) => {
        this.roles = res.data.map((role) => ({
          ...role,
          name: this.getTranslatedRole(role),
          id: role,
        }));
      });
    },
    async loadOrganisations() {
      await this.$store.dispatch('organisations/getAllOrganisations').then((res) => {
        this.organisations = [{ id: null, name: "" }, ...res.data];
      });
    },
    async loadClients() {
      await this.$store.dispatch('clients/getAllClients').then((res) => {
        this.clients = res.data.map((item) => {
          return { ...item };
        });
      });
    },

    editItem(item) {
      this.editedItem = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.first_name = item.first_name || '';
      this.editedItem.last_name = item.last_name || '';
      this.editedItem.oldEmail = item.email;
      this.editedItem.role = this.editedItem.role ? this.editedItem.role : '';
      this.editedItem.client = this.editedItem?.client?.id ? parseInt(this.editedItem?.client?.id) : '';
      this.editedItem.organisation_id = item.organisation?.id;

      this.formTitle = `${this.editedItem.first_name} ${this.editedItem.last_name}`;
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedItem = this.users.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.formTitle = item.name;
      this.dialog = true;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let formData = this.createFormData();

      let path = 'saveUser';

      if (this.editedItem.id) {
        path = 'updateUser';
      }

      await this.$store
        .dispatch('users/' + path, formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });

          this.loadAllUsers();

          this.close();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response.data.errors?.email) {
              if (error.response.data.errors?.email && error.response.data.errors?.email[0] === 'USER-0008') {
                this.$refs.emailRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.email'),
                    }),
                  ],
                });
              }
            } else {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'yellow darken-3' }));
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },

    async deleteUser() {
      this.enableDelete = false;

      await this.$store
        .dispatch('users/deleteUser', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.loadAllUsers();

            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDeleteUserModal();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.enableDelete = true;
        });
    },

    createFormData() {
      let formData = new FormData();

      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }

      formData.append('first_name', this.editedItem.first_name);
      formData.append('last_name', this.editedItem.last_name);
      formData.append('email', this.editedItem.email ? this.editedItem.email : '');
      if (this.editedItem.password) {
        formData.append('password', this.editedItem.password);
      }
      formData.append('role', this.editedItem.role ? this.editedItem.role : 1);
      if (this.editedItem.role == 'client') {
        formData.append('client_id', this.editedItem.client ? this.editedItem.client : 1);
      }
      if(this.editedItem.organisation_id ){
        formData.append('organisation_id', this.editedItem.organisation_id ? this.editedItem.organisation_id : null);
      }

      return formData;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultUser);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.formTitle = 'usersPage.newItem';
    },

    showDeleteUserModal(item) {
      this.editedItemDeleted = item;
      this.userName = item?.first_name + ' ' + item?.last_name;
      this.deleteUserModal = true;
    },

    closeDeleteUserModal() {
      this.deleteUserModal = false;
    },

    getTranslatedRole(role) {
      const roleTranslations = {
        masterAdmin: 'usersRoles.masterAdmin',
        websiteAdmin: 'usersRoles.websiteAdmin',
        orderAdmin: 'usersRoles.orderAdmin',
        client: 'usersRoles.client',
        manager: 'usersRoles.manager',
        collector: 'usersRoles.collector',
      };

      return roleTranslations[role] ? i18n.t(roleTranslations[role]) : '';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    '$i18n.locale': {
      handler() {
        this.loadRoles();
      },
    },
  },
};
</script>
